import { mapState } from "vuex";
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import notificationApi from "@/api/common/notification";
import baseComponent from "@/scripts/baseComponent";
import lazyLoadComponent from "@/scripts/lazyLoadComponent";
import SkeletonBox from "@/components/SkeletonBox";
import commonAct from '@/commonActionHandle.js';
import { editorConfig, tinyEditorInit } from '@/constant/config';
import Editor from '@tinymce/tinymce-vue';
//import { PrismEditor } from 'vue-prism-editor';
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
//import { EmailEditor } from 'vue-email-editor'
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
import emailTemplateJson from "@/constant/emailTemplateJson"
const SendEmailTaskStatus = {
    TaskNew: 1,
    TaskInprogress: 2,
    TaskDone: 3
};

const SendEmailTaskUserType = {
    AllUser : 1,
    ClassifyGroup : 2,
    EmailList: 3,
    PaidUser: 4
}

const componentPromise = (nameComp, targetName) => {
    return new Promise((resolve, reject) => {
        let compPromise = null;
        switch(nameComp){
            case 'vue-prism-editor':
                compPromise = import('vue-prism-editor')
                break;
            case 'vue-email-editor':
                compPromise = import('vue-email-editor')
                break;
        }
        compPromise.then(comp => {
            if(comp && comp[targetName]) resolve(comp[targetName])
            else reject('error')
        })
    })
};

export default {
    extends: baseComponent,
    components: {
        editor: Editor,
        //PrismEditor,
        //EmailEditor,
        PrismEditor: lazyLoadComponent({
            componentFactory: () => componentPromise('vue-prism-editor', 'PrismEditor'),
            loading: SkeletonBox, 
        }),
        EmailEditor: lazyLoadComponent({
            componentFactory: () => componentPromise('vue-email-editor', 'EmailEditor'),
            loading: SkeletonBox,
        }),
    },
    data() {
        return {
            isProccessing: false,
            isSending: false,
            isAddTask: false,
            emailTaskData: {
                data: [],
                pagingItem: {
                    querySearch: '',
                    orderBy: 'CreatedAt',
                    directionSort: 'DESC',
                    pageIndex: 1,
                    pageSize: 20,
                    outRowsNumber: 0
                }
            },
            itemTaskSelected: null,
            typeTargetSelected: 1,
            userGroupSelected: [],
            userEmailSelected: [],
            withTemplate: false,
            editor: ClassicEditor,
            editorConfig: editorConfig,
            dialogPreviewEmailVisible: true,
            sendEmailTaskStatus: SendEmailTaskStatus,
            intervalUpdate: null,
            tinyEditorInit: {
                ...tinyEditorInit,
                height: $('#ckEditorArea').height(),
                file_picker_callback: function (callback, value, meta) {
                    /* Provide file and text for the link dialog */
                    if (meta.filetype === 'file') {
                        window.selectFileWithCKFinder((fileUrl) => {
                            callback(fileUrl, { text: 'iRender Vietnam JSC' })
                        })
                    }

                    /* Provide image and alt text for the image dialog */
                    if (meta.filetype === 'image') {
                        window.selectFileWithCKFinder((fileUrl) => {
                            callback(fileUrl, { alt: 'iRender Vietnam JSC' })
                        })
                    }

                    /* Provide alternative source and posted for the media dialog */
                    if (meta.filetype === 'media') {
                        window.selectFileWithCKFinder((fileUrl) => {
                            callback(fileUrl, { text: 'iRender Vietnam JSC' })
                        })
                    }
                }
            },
            isFullScreenEmailDesign: false,
            isEmailEditorReady: false,
        }
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.auth.user,
            rolesInfo: (state) => state.auth.roles,
            staffsInfo: (state) => state.staffs,
            lowBalanceUser: (state) => state.lowBalanceUser,
            $emailAllowedChangeSource: (state) => state.emailAllowedChangeSource,
            decentralization: (state) => state.auth.decentralization,
            userTagClassifyList: (state) => state.common.userClassifyAll
        }),
        pagingRequest() {
            return {
                querySearch: this.emailTaskData.pagingItem.querySearch,
                orderBy: this.emailTaskData.pagingItem.orderBy,
                directionSort: this.emailTaskData.pagingItem.directionSort,
                pageIndex: this.emailTaskData.pagingItem.pageIndex,
                pageSize: this.emailTaskData.pagingItem.pageSize
            }
        }
    },
    mounted() {
        this.$store.dispatch('common/getAllClassify')
        this.getEmailTask()
        this.intervalUpdate = setInterval(() => {
            this.getEmailTask()
        }, 5000) 
        document.addEventListener('keydown', this.handleKeyDown);
    },
    beforeDestroy() {
        if (this.intervalUpdate) clearInterval(this.intervalUpdate)
        document.removeEventListener('keydown', this.handleKeyDown);
    },
    methods: {
        handleKeyDown(event) {
            if (event.key === 'Escape') { 
                const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
                if (isNotCombinedKey) {
                    console.log('Escape key was pressed with out any group keys')                  
                }
            }
        },
        highlighter(code) {
            return highlight(code, languages.js) //returns html
        },
        getEmailTask() {
            notificationApi
                .getEmailTask(this.pagingRequest)
                .then((response) => {
                    if (response.data && response.data.result === 0) {
                        this.$set(this.emailTaskData, 'data', response.data.data.data)
                        this.$set(this.emailTaskData, 'pagingItem', response.data.data.pagingItem)
                        //if (response.data.data.data.length > 0) {
                        //    this.$set(this, 'itemTaskSelected', Object.assign({}, response.data.data.data[0]));
                        //}
                    } else {
                        if (response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error)
                        }
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        format(percentage) {
            return percentage >= 100 ? 'Done' : `${percentage}%`
        },
        roundUp(num, precision) {
            precision = Math.pow(10, precision)
            return Math.ceil(num * precision) / precision
        },
        getPercentTask(itemTask) {
            try {
                return parseFloat(itemTask.totalSend === 0 ? 0 : this.roundUp((itemTask.progressSent / itemTask.totalSend) * 100, 2))
            } catch (error) {
                console.error(error)
                return 0
            }
        },
        handleSizeChange(val) {
            this.$set(this.emailTaskData.pagingItem, 'pageSize', val)
            this.getEmailTask()
        },
        handleCurrentChange(val) {
            this.cancelDetail()
            this.$set(this.emailTaskData.pagingItem, 'pageIndex', val)
            this.getEmailTask()
        },
        onEditorReady(editor) {
            setTimeout(() => {
                $('#ckEditorArea .cke_contents').height($('#ckEditorArea').height() - $('#ckEditorArea .cke_top').first().height() - $('#ckEditorArea .cke_bottom').first().height() - 20)
            }, 100)
        },
        tinyEditorShow() {
            $('#ckEditorArea .tox.tox-tinymce').height($('#ckEditorArea').height())
        },
        selectFileWithCKFinder(elementId) {
            window.selectFileWithCKFinder((fileUrl) => {
                this.$set(this, 'notificationImageUrl', fileUrl)
            })
        },
        onNamespaceLoaded(CKEDITOR) {
            // Add external `placeholder` plugin which will be available for each
            // editor instance on the page.
            CKEDITOR.plugins.addExternal('placeholder', '/ckeditor-plugins/plugins/placeholder', 'plugin.js')
            CKEDITOR.plugins.addExternal('colorbutton', '/ckeditor-plugins/plugins/colorbutton', 'plugin.js')
            CKEDITOR.plugins.addExternal('divarea', '/ckeditor-plugins/plugins/divarea', 'plugin.js')
            CKEDITOR.plugins.addExternal('editorplaceholder', '/ckeditor-plugins/plugins/editorplaceholder', 'plugin.js')
            CKEDITOR.plugins.addExternal('emoji', '/ckeditor-plugins/plugins/emoji', 'plugin.js')
            CKEDITOR.plugins.addExternal('justify', '/ckeditor-plugins/plugins/justify', 'plugin.js')
            CKEDITOR.plugins.addExternal('panelbutton', '/ckeditor-plugins/plugins/panelbutton', 'plugin.js')
            CKEDITOR.plugins.addExternal('autolink', '/ckeditor-plugins/plugins/autolink', 'plugin.js')
            CKEDITOR.plugins.addExternal('button', '/ckeditor-plugins/plugins/button', 'plugin.js')
            CKEDITOR.plugins.addExternal('codesnippet', '/ckeditor-plugins/plugins/codesnippet', 'plugin.js')
            CKEDITOR.plugins.addExternal('easyimage', '/ckeditor-plugins/plugins/easyimage', 'plugin.js')
            CKEDITOR.plugins.addExternal('embed', '/ckeditor-plugins/plugins/embed', 'plugin.js')
            CKEDITOR.plugins.addExternal('font', '/ckeditor-plugins/plugins/font', 'plugin.js')
            CKEDITOR.plugins.addExternal('image2', '/ckeditor-plugins/plugins/image2', 'plugin.js')
            CKEDITOR.plugins.addExternal('tableresize', '/ckeditor-plugins/plugins/tableresize', 'plugin.js')
            //CKEDITOR.plugins.addExternal('lineheight', '/ckeditor-plugins/plugins/lineheight', 'plugin.js');
        },
        editorLoaded() {
            // Pass your template JSON here 
            this.isEmailEditorReady = true;
            if(this.itemTaskSelected.isAddTask){
                this.$refs.emailEditor.editor.loadDesign({});
            }
            else{
                if(this.itemTaskSelected.emailJsonData){
                    this.$refs.emailEditor.editor.loadDesign(JSON.parse(this.itemTaskSelected.emailJsonData));
                }
                else{
                    this.$refs.emailEditor.editor.loadDesign({});
                }
            }
            this.$refs.emailEditor.editor.addEventListener('design:loaded', (data) => {
                this.hideLoading()
            })
            this.$refs.emailEditor.editor.registerCallback('selectImage', function (data, done) {
                window.selectFileWithCKFinder((fileUrl) => { 
                    const feUrl = "";
                    done({ url: fileUrl });
                });
            }); 
        },
        editorReady() {
          console.log('editorReady');
        },
        loadTemplate(){
            if(this.isEmailEditorReady){
                this.showLoading() 
                this.$refs.emailEditor.editor.loadDesign(emailTemplateJson);
            }
            else {
                this.$message({
                    message: 'Editor not ready. please try again later.',
                    type: 'warning'
                });
            }
        },
        saveDesign() {
            return new Promise((resolve, reject) => {
                try{
                    this.$refs.emailEditor.editor.saveDesign((design) => {
                        console.log('saveDesign', design)
                        resolve(design)
                    })
                }catch(error){
                    reject(error)
                }
            })
        },
        exportHtml() {
            return new Promise((resolve, reject) => {
                try{
                    this.$refs.emailEditor.editor.exportHtml((data) => {
                        console.log('exportHtml', data)
                        resolve(data)
                    })
                }catch(error){
                    reject(error)
                }
            })
        },
        selectTask(itemTask) {
            this.userEmailSelected = itemTask.sendTo
            this.$set(this, 'itemTaskSelected', Object.assign({}, { ...itemTask }))
            this.$set(this, 'withTemplate', itemTask.useTemplate)
        },
        withTemplateChanged(valueChanged) {
            this.$set(this, 'itemTaskSelected', {
                ...this.itemTaskSelected,
                emailTemplate: valueChanged ? this.contentTemplate.default.content : '[[EmailBodyContent]]',
                useTemplate: valueChanged
            })
        },
        addTask() {
            let contentTemplate = require('@/constant/emailTemplateContent.js')
            this.$set(this, 'itemTaskSelected', {
                id: null,
                taskName: '',
                emailSubject: '',
                emailTemplate: '',
                emailContent: '',
                sendTo: '',
                taskStatus: 1,
                targetUserType: 1,
                typeOfEmail: 1,
                totalSend: 0,
                progressSent: 1,
                isRunning: false,
                useTemplate: true
            })
            this.isAddTask = true
        },
        cancelDetail() {
            this.isAddTask = false
            this.$set(this, 'itemTaskSelected', null)
        },
        copyTask(itemTask) {
            let newTaskCopy = Object.assign({}, itemTask)
            newTaskCopy.id = null
            this.$set(this, 'itemTaskSelected', newTaskCopy)
            this.isAddTask = true
        },
        saveEmailTask() {
            commonAct.showConfirm(`Save send email task ?`, () => {         
                Promise.all([this.saveDesign(), this.exportHtml()]).then((exportData) => {
                    const designData = exportData[0];
                    const htmlData = exportData[1];  
                    //console.log('exportHtml', htmlData)
                    this.showLoading()       
                    this.itemTaskSelected.sendTo = null
                    this.itemTaskSelected.isAddTask = this.isAddTask
                    if (this.itemTaskSelected.targetUserType === SendEmailTaskUserType.ClassifyGroup) this.itemTaskSelected.sendTo = this.userGroupSelected.join(',')
                    if (this.itemTaskSelected.targetUserType === SendEmailTaskUserType.EmailList) this.itemTaskSelected.sendTo = this.userEmailSelected.replace(/\s+/g, '')
                    let request = Object.assign({}, this.itemTaskSelected)
                    this.$set(request, 'useTemplate', this.withTemplate)
                    this.$set(request, 'emailJsonData', JSON.stringify(designData))
                    this.$set(request, 'emailContent', htmlData.html)
                    notificationApi
                        .saveEmailTask(request)
                        .then((response) => {
                            if (response.data && response.data.result === 0) {
                                this.showSuccessToast('Save send email task succeed !')
                                this.isAddTask = false
                                this.$set(this, 'itemTaskSelected', response.data.data)
                                this.handleCurrentChange(1)
                            } else {
                                if (response.data.message !== null && response.data.message !== '') {
                                    console.error(response.data.message || this.$lang.common.error)
                                    commonAct.showError(response.data.message || this.$lang.common.error)
                                }
                            }
                            this.hideLoading()
                        })
                        .catch((error) => {
                            this.hideLoading()
                            console.error(error)
                        })
                })
            })
        },
        startStopEmailTask() {
            commonAct.showConfirm(`${!this.itemTaskSelected.isRunning ? 'Start' : 'Stop'} email task ${this.itemTaskSelected.taskName} ?`, () => {
                notificationApi
                    .startStopEmailTask(this.itemTaskSelected.id, !this.itemTaskSelected.isRunning)
                    .then((response) => {
                        if (response.data && response.data.result === 0) {
                            this.showSuccessToast('Perform succeed !')
                            this.itemTaskSelected.isRunning = !this.itemTaskSelected.isRunning
                            this.handleCurrentChange(1)
                            setTimeout(() => {
                                this.handleCurrentChange(1)
                            }, 1000)
                        } else {
                            if (response.data.message !== null && response.data.message !== '') {
                                console.error(response.data.message || this.$lang.common.error)
                                commonAct.showError(response.data.message || this.$lang.common.error)
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            })
        }
    }
}